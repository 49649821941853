<template>
<div class="login-warper">
  <div class="head-top">
      <img class="gwbLogo" src="../img/gwbLogo.png" alt="">
      <span class="line"></span>
      <img class="logo" src="../img/logo.png" alt="">
      <span class="line"></span>
      <!-- <span>中天钢铁集团统一收款平台</span> -->
      <span>智慧金融/支付服务平台</span>
    </div>
  <div class="login-container"
       ref="login"
       @keyup.enter="handleLogin">
    <top-color v-show="false"></top-color>
    <div class="login-weaper animated bounceInDown">
      <!-- <div class="login-left">
        <div class="login-time">
          {{time}}
        </div>
        <img class="img" src="/img/logo.png" alt="">
        <p class="title">{{ $t('login.info') }}</p>
      </div> -->
      <div class="login-left">
        <img class="img" src="../img/login_bg.png" alt="">
      </div>
      <div class="login-border">
        <div class="login-main">
          <h4 class="login-title">
            <!-- {{ $t('login.title') }}{{website.title}} -->
            请您登录使用
            <!-- <top-lang></top-lang> -->
          </h4>
          <userLogin v-if="activeName==='user'"></userLogin>
          <codeLogin v-else-if="activeName==='code'"></codeLogin>
          <thirdLogin v-else-if="activeName==='third'"></thirdLogin>
          <div class="login-menu">
            <!-- <a href="#" @click.stop="activeName='user'">{{ $t('login.userLogin') }}</a> -->
            <!-- <a href="#" @click.stop="activeName='third'">{{ $t('login.thirdLogin') }}</a> -->
            <!-- <a href="#">新用户？点击这里注册</a> -->
          </div>
        </div>
       
      </div>
    </div>
    </div>
  </div>
</template>
<script>
  import userLogin from "./userlogin";
  import codeLogin from "./codelogin";
  import thirdLogin from "./thirdlogin";
  import {mapGetters} from "vuex";
  import {dateFormat} from "@/util/date";
  import {validatenull} from "@/util/validate";
  import topLang from "@/page/index/top/top-lang";
  import topColor from "@/page/index/top/top-color";
  import {getQueryString, getTopUrl} from "@/util/util";

  export default {
    name: "login",
    components: {
      userLogin,
      codeLogin,
      thirdLogin,
      topLang,
      topColor
    },
    data() {
      return {
        time: "",
        activeName: "user",
        socialForm: {
          tenantId: "000000",
          source: "",
          code: "",
          state: "",
        }
      };
    },
    watch: {
      $route() {
        this.handleLogin();
      }
    },
    created() {
      this.handleLogin();
      this.getTime();
    },
    mounted() {
    },
    computed: {
      ...mapGetters(["website", "tagWel"])
    },
    props: [],
    methods: {
      getTime() {
        setInterval(() => {
          this.time = dateFormat(new Date());
        }, 1000);
      },
      handleLogin() {
        
        const topUrl = getTopUrl();
        const redirectUrl = "/oauth/redirect/";
        this.socialForm.source = getQueryString("source");
        this.socialForm.code = getQueryString("code");
        this.socialForm.state = getQueryString("state");
        if (validatenull(this.socialForm.source) && topUrl.includes(redirectUrl)) {
          let source = topUrl.split("?")[0];
          source = source.split(redirectUrl)[1];
          this.socialForm.source = source;
        }
        if (!validatenull(this.socialForm.source) && !validatenull(this.socialForm.code) && !validatenull(this.socialForm.state)) {
          const loading = this.$loading({
            lock: true,
            text: '第三方系统登录中,请稍后。。。',
            spinner: "el-icon-loading"
          });
          
          this.$store.dispatch("LoginBySocial", this.socialForm).then(() => {
            window.location.href = topUrl.split(redirectUrl)[0];
            
            this.$router.push({path: this.tagWel.value});
            loading.close();
          }).catch(() => {
            loading.close();
          });
        }
      }
    }
  };
</script>

<style lang="scss">
  @import "@/styles/login.scss";
  .login-warper{
    width: 100%;
    height: 100%;
  }
  .login-left{
    min-height: 423px;
    .img{
      width: 100%;
      height: 423px;
      // min-height: 242px;
    }
  }
  .login-weaper{
    height: 423px;
  }
  .login-border{
      min-height: 423px;
      h4{ margin: 20px 0}
    }
    .login-submit{
      margin-top: 10px;
    }
    .login-form{
      margin-bottom: 0px;
    }
    .login-menu{
      margin-top: 20px;
    }

    .head-top{
      height: 80px;
      display: flex;
      justify-items: center;
      align-items: center;
      font-size: 18px;
      color: #000000;
      .logo{
        width: 161px;
        height: 42px;
        // margin-left: 37px;
        margin-right: 15px;
        margin-left: 15px;
      }
       .gwbLogo{
        width: 130px;
        height: 40px;
        margin-left: 37px;
        margin-right: 15px;
      }
      .line{
        border-left: solid 1px rgb(206, 201, 201); height: 28px; margin-right: 15px;
      }
    }
    .login-container{
      background: #EEEFF0;
      height: calc(100% - 80px) ;
    }
</style>
