<template>
  <el-container>
    <!-- 头部区域 -->
    <el-header >
      <img :src="url1" style="width: 100%;float: left;max-width: 300px;">
      <div style="clear: both"></div>
    </el-header>
    <!-- 页面主体区域 -->
    <el-main>
      <div style="padding: 30px 30px">
        <div class="payState">
          <span>支付状态：</span>
          <span v-if="flag === 3">付款成功</span>
          <span v-if="flag === 2">付款失败</span>
          <span v-if="flag === 0">未付款</span>
          <span v-if="flag === 1">付款中</span>
        </div>
        <el-divider></el-divider>
        <!-- 表单一 -->
        <div class="Form">
          <div class="Form_title">{{itemName}}</div>
          <div>
            <label>收款方：</label>
            <span>{{payeeName}}</span>
          </div>
          <div>
            <label>金额：</label>
            <span>￥</span>
            <span>{{money}}</span>
          </div>
          <div>
            <label>支付用户：</label>
            <span>{{payer}}</span>
          </div>
          <div>
            <label>备注：</label>
            <span>{{remark}}</span>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="Form">
          <div>
            <label>支付方式：</label>
            <span>{{payMethod}}</span>
          </div>
          <div>
            <label>支付单号：</label>
            <span>{{orderNo}}</span>
          </div>
          <div>
            <label>交易流水号：</label>
            <span>{{tradeNo}}</span>
          </div>
          <div>
            <label>付款时间：</label>
            <span>{{paytime}}</span>
          </div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
  import {tradeAll} from "../../api/pay/alipay";
  const ZT = require('../../page/img/paylogo.png')
  export default {
    data () {
      return {
        itemName: '', // 收款名项
        payeeName: '', // 收款方
        money: '', // 金额
        payer: '', // 支付用户
        remark: '', // 备注
        payMethod: '', // 支付方式
        orderNo: '', // 支付单号
        tradeNo: '', // 交易流水号
        paytime: '', // 付款时间
        url1: ZT,
        flag: ''
      }
    },
    mounted () {
      //this.orderNo = sessionStorage.getItem('outTradeNo')
      this.SuccessFind()
    },
    methods: {
      SuccessFind () {
        this.orderNo = this.$route.query.orderNo
        tradeAll(this.orderNo).then((r) => {
          console.log(r)
          if (r.data.code === 200) {
            if (r.data.data !== null) {
              this.itemName = r.data.data[0].itemName
              this.payeeName = r.data.data[0].payeeName
              this.money = parseFloat(Number(r.data.data[0].money) / 100).toFixed(2)
              this.payer = r.data.data[0].payer
              this.remark = r.data.data[0].remark
              this.flag = r.data.data[0].status
              // this.payMethod = r.data.data[0].payMethod
              if (r.data.data[0].payMethod === 'AliPay') {
                this.payMethod = '支付宝'
              } else {
                this.payMethod = '微信'
              }
              this.orderNo = r.data.data[0].orderNo
              this.tradeNo = r.data.data[0].tradeNo
              this.paytime = r.data.data[0].payTime
            }
          }
        })
      }
    }
  }
</script>

<style scoped>
  .el-container{
    height: 100%;
    width: 100%;
  }
  .el-header{
    background-color: white;
    padding: 20px 30px;
  }
  .el-main{
    background-color: #f2f2f2;
    padding: 0;
  }
  .payState{
    font-size: 20px;
    font-weight: bold;
    color: #48b325;
  }
  .payTitle{
    font-weight: bold;
  }
  .Form{
    line-height: 30px;
    color: #696a6d;
  }
  .Form_title{
    font-weight: bold;
    color: black;
  }

</style>
