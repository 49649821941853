<template>
  <div class="payWarper">
    <el-container>
    <!-- 头部区域 -->
    <el-header>
      <img :src="url1" style="width: 141px; height: 34px">
      <div>
        <span class="line"></span>
        <span class="headTitle">中天钢铁集团统一收款平台</span>
      </div>
    </el-header>
    <!-- 页面主体区域 -->
    <el-main>
      <div style="padding: 20px 15px">
        <div class="payTitle">{{d.itemName}}</div>
        <div class="payee">
          <div style="display: inline">收款方：</div>
          <div style="display: inline">{{d.payeeName}}/{{d.departName}}</div>
        </div>
        <el-divider v-if="paytype === 1"></el-divider>
        <!-- 支付金额 -->
        <!-- 非固定金额，手动输入 -->
        <!-- <div v-if="paytype === 2">
          <div style="float: left;color:#909399">支付金额：</div>
          <div style="float: left;font-weight: bold;font-size: 20px">
            <div style="display: inline">￥</div>
            <input class="moneyInput" v-model="d.money2" placeholder="请输入支付金额">
          </div>
        </div> -->
        <!-- 固定金额，直接显示 -->
        <!-- <div v-if="paytype === 1">
          <div style="float: left;color:#909399">支付金额：</div>
          <div style="float: left;font-weight: bold;font-size: 20px">
            <div style="display: inline">￥</div>
            <div style="display: inline">{{d.money2}}</div>
          </div>
        </div> -->
        <!-- <div style="clear: both"></div> -->
        <!-- 表单 -->
        <!--关闭状态-->
        <div v-if="!isState" style="margin: auto;text-align: center">
          <img :src="url5" style="width: 50%;">
          <div style="font-size: 18px;">支付项目已关闭</div>
          <div style="font-size: 12px;color:#909399;padding-top: 10px;">如在支付过程中遇到问题，请联系您的收款方。</div>
        </div>
        <div class="payForm" v-if="isState">
          <div class="payMoney" v-if="paytype === 1">
            <label>支付金额：</label>
            <span>￥{{d.money2}}</span>
          </div>
          <div class="payFormItem" style="max-width: 700px;" v-if="paytype === 2">
            <label>支付金额：</label>
            <input placeholder="点击这里输入" style="border: none;" 
            v-model="d.money2" :readonly="d.paytype == 1 ? readonly : false ">
          </div>
          <div class="payFormItem" style="max-width: 700px;">
            <label>姓名：</label>
            <input placeholder="点击这里输入" style="border: none;" v-model="d.payer">
          </div>
          <div class="payFormItem" style="max-width: 700px;">
            <label>手机：</label>
            <input placeholder="点击这里输入" style="border: none;" v-model="d.phone">
          </div>
          <div class="payFormItem" style="max-width: 700px;">
            <label>备注：</label>
            <input placeholder="如需备注，点击这里输入" style="border: none;" v-model="d.remark">
          </div>
        </div>
        <!-- 支付方式 -->
        <div class="payWay" v-if="isState">
          <div>支付方式：</div>
          <div class="payType">
            <div class="payTypeItem" v-if="isAliPay == 1? true : isAliPay == 0 ? true : false">
              <el-radio v-model="d.payMethod" label="AliPay"></el-radio>
              <img :src="url2">
              <label >支付宝</label>
            </div>
            <div class="payTypeItem"  v-if="isAliPay == 2? true : isAliPay == 0 ? true : false">
              <el-radio v-model="d.payMethod" label="MicroMessenger"></el-radio>
              <img :src="url3" >
              <label >微信支付</label>
            </div>
            <!-- <el-radio style="zoom:120%" v-model="d.payMethod" label="AliPay">
              <img :src="url2" style="width: 30px;margin-right: 15px;position: absolute;margin-top:-7px">
              <label style="margin-left:40px;color:black;font-size:14px">支付宝</label>
            </el-radio> -->
            <!-- <el-radio style="zoom:120%" v-model="d.payMethod" label="MicroMessenger">
              <img :src="url3" style="width: 30px;margin-right: 15px;position: absolute;margin-top:-7px">
              <label style="color:black;font-size:14px;margin-left: 40px;">微信支付</label>
            </el-radio> -->
          </div>
        </div>
        <div style="clear:both"></div>
        <div class="Dialog">
          <el-dialog title="请您在新打开的页面上完成支付"
                     :visible.sync="dialogVisible"
                     width="90%" class="ed">
            <el-divider></el-divider>
            <div>支付完成，请勿关闭此窗口，</div>
            <div>请根据您的付款情况选择相应操作。</div>
            <div  class="dialog-footer" style="padding-top:20px;">
              <div >
                <el-button type="primary" @click="verifyPay" class="dialogBtn">已完成付款</el-button>
              </div>
              <div>
                <el-button type="primary" class="dialogBtn" @click="PayAgain">继续付款</el-button>
              </div>
            </div>
          </el-dialog>
        </div>
        <div class="Dialog">
          <el-dialog title="请您在新打开的页面上完成支付"
                     :visible.sync="dialogVisible2"
                     width="90%" class="ed">
            <div style="text-align: center;">打开微信扫一扫支付</div>
            <div style="text-align: center;">
              <img :src="'data:image/png;base64,' + imgSrc">
            </div>
            <div  class="dialog-footer" style="padding-top:20px;">
              <div style="width: 50%;text-align: center;display: inline-block">
                <el-button type="primary"  @click="verifyPay" class="dialogBtn">已完成付款</el-button>
              </div>
              <div style="width: 50%;text-align: center;display: inline-block">
                <el-button type="primary" class="dialogBtn" @click="cancel">取消</el-button>
              </div>
            </div>
          </el-dialog>
        </div>
        <div style="margin-top: 10px" v-if="isState">
          <el-button @click="encryption" class="payBtn" type="warning">去支付</el-button>
        </div>
      </div>
    </el-main>
    <div id="submitFormDiv"></div>
  </el-container>
  </div>
</template>

<script>
  import AEC from "@/api/aes"
  import {alipay, getDetail, getOrder, getVerifyPay} from "../../api/pay/alipay";
  const ZT = require('../../page/img/logo01.png')
  const pay = require('../../page/img/alipay.png')
  const wechat = require('../../page/img/wechat.png')
  const stateimg = require('../../page/img/img_colsed.png')
  export default {
    data () {
      return {
        labelPosition: 'left',
        dialogVisible: false,
        dialogVisible2: false,
        isAliPay:0,
        imgSrc:'',
        winUrl:'',
        isState:true,
        code:'',
        //  数据源
        // 密钥key,使用后端传过来的密钥
        key: '',
        appId: '',
        ts: '', // 时间戳
        nonce: '',
        paytype: 1,
        url1: ZT,
        url2: pay,
        url3:wechat,
        url5:stateimg,
        d: {
          // 支付金额
          money: '',
          // 项目名称
          itemName: '',
          // 收款方
          payeeName: '',
          // 付款人姓名
          payer: '',
          // 手机号
          phone: '',
          // 备注
          remark: '',
          // 支付方式
          payMethod: 'AliPay',
          // 收款部门名称
          departName: '',
          // 订单号
          orderNo: '',
          itemId: '',
          money2: '',
        },
        itemId: '',
        mobileflag:this.isMobile()
      }
    },
    methods: {
      // 获取支付订单信息
      getPayDetail () {
        this.itemId = this.$route.query.id
        var orN = sessionStorage.getItem('orderNo')
        //(orN)
        if(orN == this.itemId){
          this.d = JSON.parse(sessionStorage.getItem('data'))
          this.dialogVisible = true
        }
        // 从URL中获取itemId信息
        console.log(this.$route.query.id)
        // 再根据itemId查询订单信息，并渲染页面
        getDetail(this.itemId).then((r) => {
          console.log(r)
          if (r.status === 200) {
            this.d.itemName = r.data.data.itemName
            this.d.payeeName = r.data.data.payeeName
            //判断支付项目是否关闭
            if(r.data.data.state === 0){//关闭
              this.isState = false
            }
            // 判断金额是否为固定金额，固定金额直接显示，非固定需要手动输入
            if(r.data.data.payType == 1){
              this.d.money2 = parseFloat(Number(r.data.data.money) / 100).toFixed(2)
              this.d.money = r.data.data.money
            }
            this.d.departName = r.data.data.departName
            this.key = r.data.data.secretkey
            this.appId = r.data.data.appId
            this.paytype = r.data.data.payType
          } else {
            return this.$message.error('订单信息加载失败，请重试！')
          }
        })
      },
      // 获取支付订单号——orderNo
      getOrderNo () {
        if(this.d.orderNo != ''){
          return
        }

        getOrder().then((r) => {
          console.log(r)
          if (r.data.code === 200) {
            this.d.orderNo = r.data.data
            console.log(this.d.orderNo)
          } else {
            return this.$message.error('订单号获取失败！')
          }
        })
      },
      // 加密传输——支付信息
      encryption () {
        this.ts = new Date().getTime()
        this.d.ts = this.ts
        if(this.d.payMethod == 'AliPay'){
          this.d.money = this.d.money2
        } else {
          this.d.money = this.d.money2*100
        }
        console.log(this.d.money)
        this.d.key = this.key
        this.d.itemId = this.itemId
        console.log(this.key)
        this.nonce = this.createCode(6)
        this.d.nonce = this.nonce
        //alert(this.code)
        console.log(this.d)
        const a = AEC.encrypt(JSON.stringify(this.d), this.key)
        // 验证信息
        if (this.d.payer.length === 0 || this.d.payer.split('').join('').length === 0) {
          return this.$message.error('请填写支付人员姓名！')
        }
        if (this.d.phone.length <= 10 || this.d.phone === '' || !/^1[23456789]\d{9}$/.test(this.d.phone)) {
          return this.$message.error('请正确填写支付人员手机号！')
        }
        // 发起支付请求
        alipay(this.d.orderNo,this.appId,this.nonce,this.ts,this.code,a).then((r) => {
          sessionStorage.setItem('orderNo',this.itemId)
          sessionStorage.setItem('data',JSON.stringify(this.d))
          console.log(r)
          if (r.status === 200) {
            if(this.d.payMethod == 'AliPay'){
              // 打开弹框

              // 跳转支付宝支付页面
              // const divForm = document.getElementsByTagName('divform')
              // if (divForm.length) {
              //   document.body.removeChild(divForm[0])
              // }
              // const div = document.createElement('divform')
              // div.innerHTML = r.data
              // document.body.appendChild(div)
              // document.forms[0].setAttribute('target', '_blank')
              // document.forms[0].submit()
              // window.location.href = r.data
              document.querySelector('#submitFormDiv').innerHTML = r.data;
              this.executeScript(r.data);
              this.dialogVisible = true
            } else if ( this.d.payMethod == 'MicroMessenger'){//微信支付
              console.log('flag:'+ this.mobileflag)
              if(this.mobileflag && this.isAliPay != 2){//true 手机端网页支付H5
                // 打开弹框
                window.location.href = r.data.h5_url
                this.dialogVisible = true
              }else if (this.isAliPay == 2){//手机端公众号支付&网页端微信打开
                //alert(r)
                //alert(this.d.orderNo)
                this.dialogVisible = true
                window.WeixinJSBridge.invoke(
                  'getBrandWCPayRequest', {
                    'appId': r.data.appId, // 公众号名称，由商户传入
                    'timeStamp': r.data.timeStamp, // 时间戳，自1970年以来的秒数
                    'nonceStr': r.data.nonceStr, // 随机串
                    'package': r.data.package,
                    'signType': r.data.signType, // 微信签名方式：
                    'paySign': r.data.paySign // 微信签名
                  },
                  function (res) {
                    //alert(JSON.stringify(res))
                    if (res.err_msg === 'get_brand_wcpay_request:ok') {
                      //alert(true)
                      // 使用以上方式判断前端返回,微信团队郑重提示：
                      // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    }
                  }
                )
              }else if (!this.mobileflag && this.isAliPay != 2){//扫码支付
                this.dialogVisible2 = true
                console.log(r.data)
                this.imgSrc = r.data
              }
            }
          } else {
            this.$alert('错误：' + r.data, '提示', {
              confirmButtonText: '确定'
            })
          }
        })
      },
      //支付宝执行
      executeScript(html){
        let reg = /<script[^>]*>([^\x00]+)$/i;
        let htmlBlock = html.split("<\/script>");
        for (let i in htmlBlock){
          let blocks;
          if (blocks = htmlBlock[i].match(reg)){
            let code = blocks[1].replace(/<!--/, '');
            try{
              eval(code) //执行脚本
            }
            catch (e){
            }
          }
        }
      },
      //继续支付
      PayAgain(){
        getOrder().then((r) => {
          console.log(r)
          if (r.data.code === 200) {
            this.d.orderNo = r.data.data
            //console.log(this.d.orderNo)
            sessionStorage.clear()
          } else {
            return this.$message.error('订单号获取失败！')
          }
        })
        this.dialogVisible = false
      },
      createCode (length) {
        let code = ''
        const codeLength = length
        const random = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
        // const random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9)
        for (let i = 0; i < codeLength; i++) {
          const index = Math.floor(Math.random() * 9)
          code += random[index]
        }
        return code
      },
      // 弹框按钮——“已完成支付”，验证支付状态
      verifyPay () {
        getVerifyPay(this.d.orderNo,this.d.payMethod).then((r) => {
          if (r.data.code === 200) {
            this.$router.push({ path: '/SuccessPay?flag=0&orderNo='+ this.d.orderNo })
          }
          if (r.data.code == null || r.data.code == undefined) {
            this.$router.push({ path: '/SuccessPay?flag=1&orderNo='+ this.d.orderNo })
          }
        },(error) =>{
          console.log(error)
          this.$router.push({ path: '/SuccessPay?flag=1&orderNo='+ this.d.orderNo })
        })
      },
      //判断是什么
      isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
      },
      //取消支付
      cancel(){
        this.dialogVisible2 = false
      },
      //
      getCode(){
        var code = this.getUrlParam().code
        var local = 'https://pay.hmifo.com/#/GoToPay?id=' + this.$route.query.id
        if(code == null || code == ''){
          window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx43dfa54492c30238&redirect_uri='+ encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=123#wechat_redirect'
        }else{
          this.code = code
          console.log(code)
        }
        // console.log('----',code)
      },
      getUrlParam(){//获取url返回参数
        var url = location.search
        this.winUrl = url
        //alert('url:' + this.winUrl)
        var theRequest = new Object()
        if (url.indexOf("?") != -1) {
          var str = url.substr(1)
          var strs = str.split("&")
          for(var i = 0; i < strs.length; i ++) {
            theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
          }
        }
        return theRequest
      },
      //判断当前浏览器
      IsWeixinOrAlipay(){
         var ua = window.navigator.userAgent.toLowerCase();
      //判断是不是微信
        if ( ua.match(/MicroMessenger/i) == 'micromessenger' ) {
          this.isAliPay = 2
          this.d.payMethod = 'MicroMessenger'
          this.getCode()
          return
         } else if (ua.match(/AlipayClient/i) == 'alipayclient') {//判断是不是支付宝
          this.isAliPay = 1
          this.d.payMethod = 'AliPay'
          return
        } else {//哪个都不是
          this.isAliPay = 0
          return
        }

  }
    },
    mounted () {
      // 加载订单信息
      this.getPayDetail()
      // 获取订单号
      this.getOrderNo()
      //this.IsWeixinOrAlipay()
      console.log(this.isAliPay)
    },
    beforeRouteEnter(to,from,next){
      console.log(to)
      console.log(from)
      // if(to.name == 'GoToPay'){
      //   alert(1)
      // }
      next(vm =>{
        vm.IsWeixinOrAlipay()
      })
    }
  }
</script>

<style scoped>
.payWarper{
  max-width: 750px;
  margin: 0 auto;
  height: 100%;
}
.payMoney{
  font-size: 14px;
  color: #898989;
  margin-top: -5px;
}
input{ width: 65%;}
/deep/ .el-dialog__body{ padding: 0px 20px 25px 20px; line-height: 22px;}
/deep/ .el-divider--horizontal{
  margin: 12px 0px 12px !important
}

 @media screen and (max-width: 992px) {
  /deep/ .el-dialog{
    width: 88% !important;
  } 
}
.dialog-footer{
  position: relative;
  overflow: hidden;
}
.dialog-footer > div{
  float: left;
  width: 50%;
  text-align: center;
}
.dialog-footer .dialogBtn{
  width: 80%; padding: 10px !important; 
}
.payMoney span{
  font-size: 18px;
  color: #000000;
}
  .line{
    border-right: solid 1px #000000;
    margin:0px 10px;
  }
  .headTitle{
    font-size: 14px;
    font-weight: bold;
  }


  .el-container{
    height: 100%;
    width: 100%;
  }
  .el-header{
    background-color: white;
    /* padding: 20px 30px; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding: 0 15px;

  }
  .el-main{
    background-color: #f2f2f2;
    padding: 0;
  }
  .payTitle{
    font-weight: bold;
    font-size: 20px;
  }
  .payee{
    color:#898989;
    margin-top: 10px;
    font-size: 14px;
  }
  .payForm{
    margin-top: 20px;
  }
  .payFormItem{
    background-color: white;
    padding: 13px 10px;
    margin-top: 10px;
    font-size: 14px;
    label{
      font-weight: bold;
    }
    input{
      border: none;
      outline: none;
      font-size: 16px;
    }
  }
  .payWay{
    margin-top: 20px;
    font-size: 14px;
    color: #898989;
    display: flex;
    justify-content: flex-start;
  }
  .payType{
    font-size: 17px;
    color: #000000;
    width: calc(100% - 80px);
    margin-top: -14px;
  }
  .payType img{
    width: 30px; height: 30px; margin-right: 10px;
  }
  .payTypeItem{ display: flex; align-items: center;
   border-bottom: solid 1px #C8C8C8; height: 50px;}
   .payTypeItem:last-child{ border: none;}
  /deep/.el-radio__label{ display: none;}
  /deep/ .el-radio{ margin-right: 15px;}
  .payBtn{
    width: 80%;
    display: block;
    margin: 0 auto;
    color: black;
    /* max-width: 350px; */
  }
  .Dialog{
    /deep/.el-dialog{
      max-width: 400px;
    }
    /deep/.el-dialog__header{
      padding: 20px 15px 0px 15px;
    }
    /deep/.el-dialog__title{
      line-height: 0px;
      font-size: 14px;
    }
    /deep/.el-dialog__body{
      padding: 0px 20px 30px 20px;
    }
  }
  .dialogBtn{
    width: 60%;
  }
  .moneyInput{
    display: inline;
    border: none;
    background-color: #f2f2f2;
    font-size: 20px;
    font-weight: bold;
    width: 150px;
    outline: none;
  }
  .el-button{
    padding: 12px !important;
  }
</style>
