<template>
  <div class="payWarper">
    <el-container>
      <!-- 头部区域 -->
      <el-header>
        <img :src="url1" style="width: 141px; height: 34px">
        <div>
          <span class="line"></span>
          <span class="headTitle">中天钢铁集团统一收款平台</span>
        </div>
      </el-header>
      <!-- 页面主体区域 -->
      <el-main>
        <!--<div style="padding: 20px 15px">-->
          <!--<div class="payTitle">{{d.itemName}}</div>-->
          <!--<div class="payee">-->
            <!--<div style="display: inline">收款方：</div>-->
            <!--<div style="display: inline">{{d.payeeName}}/{{d.departName}}</div>-->
          <!--</div>-->
          <!--<el-divider v-if="paytype === 1"></el-divider>-->
          <!--&lt;!&ndash; 表单 &ndash;&gt;-->
          <!--<div class="payForm">-->
            <!--&lt;!&ndash;paytype=1 固定金额，直接显示&ndash;&gt;-->
            <!--<div class="payFormItem" v-if="paytype === 1">-->
              <!--<label>支付金额：</label>-->
              <!--<span>￥{{d.money2}}</span>-->
            <!--</div>-->
            <!--&lt;!&ndash;paytype=2 非固定金额，用户手动输入&ndash;&gt;-->
            <!--<div class="payFormItem" style="max-width: 700px;" v-if="paytype === 2">-->
              <!--<label>支付金额：</label>-->
              <!--<input placeholder="点击这里输入" style="border: none;"-->
                     <!--v-model="d.money2" :readonly="d.paytype == 1 ? readonly : false ">-->
            <!--</div>-->
            <!--<div class="payFormItem" style="max-width: 700px;">-->
              <!--<label>姓名：</label>-->
              <!--<input placeholder="点击这里输入（最大10个字符）" style="border: none;" v-model="d.payer" maxlength="10">-->
            <!--</div>-->
            <!--<div class="payFormItem" style="max-width: 700px;">-->
              <!--<label>工号：</label>-->
              <!--<input placeholder="点击这里输入" style="border: none;" v-model="d.jobNumer" maxlength="10">-->
            <!--</div>-->
            <!--<div class="payFormItem" style="max-width: 700px;">-->
              <!--<label>手机：</label>-->
              <!--<input placeholder="点击这里输入" style="border: none;" v-model="d.phone">-->
            <!--</div>-->
            <!--<div class="payFormItem" style="max-width: 700px;">-->
              <!--<label>缴费类型：</label>-->
              <!--<avue-select placeholder="请选择缴费类型" v-model="d.remark" :dic="dicStatus" class="paySelect"></avue-select>-->
            <!--</div>-->
          <!--</div>-->
          <!--&lt;!&ndash; 支付方式 &ndash;&gt;-->
          <!--<div class="payWay">-->
            <!--<div>支付方式：</div>-->
            <!--<div class="payType">-->
              <!--<div class="payTypeItem" v-if="isAliPay == 1? true : isAliPay == 0 ? true : false">-->
                <!--<el-radio v-model="d.payMethod" label="AliPay"></el-radio>-->
                <!--<img :src="url2">-->
                <!--<label >支付宝</label>-->
              <!--</div>-->
              <!--<div class="payTypeItem"  v-if="isAliPay == 2? true : isAliPay == 0 ? true : false">-->
                <!--<el-radio v-model="d.payMethod" label="MicroMessenger"></el-radio>-->
                <!--<img :src="url3" >-->
                <!--<label >微信支付</label>-->
              <!--</div>-->
            <!--</div>-->
          <!--</div>-->
          <!--<div style="clear:both"></div>-->
          <div class="Dialog">
            <el-dialog title="请您在新打开的页面上完成支付"
                       :visible.sync="dialogVisible"
                       width="90%" class="ed">
              <el-divider></el-divider>
              <div>支付完成，请勿关闭此窗口，</div>
              <div>请根据您的付款情况选择相应操作。</div>
              <div  class="dialog-footer" style="padding-top:20px;">
                <div >
                  <el-button type="primary" @click="verifyPay" class="dialogBtn">已完成付款</el-button>
                </div>
                <div>
                  <el-button type="primary" class="dialogBtn" @click="PayAgain">继续付款</el-button>
                </div>
              </div>
            </el-dialog>
          </div>
          <!--<div class="Dialog">-->
            <!--<el-dialog title="请您在新打开的页面上完成支付"-->
                       <!--:visible.sync="dialogVisible2"-->
                       <!--width="90%" class="ed">-->
              <!--<div style="text-align: center;">打开微信扫一扫支付</div>-->
              <!--<div style="text-align: center;">-->
                <!--<img :src="'data:image/png;base64,' + imgSrc">-->
              <!--</div>-->
              <!--<div  class="dialog-footer" style="padding-top:20px;">-->
                <!--<div style="width: 50%;text-align: center;display: inline-block">-->
                  <!--<el-button type="primary"  @click="verifyPay" class="dialogBtn">已完成付款</el-button>-->
                <!--</div>-->
                <!--<div style="width: 50%;text-align: center;display: inline-block">-->
                  <!--<el-button type="primary" class="dialogBtn" @click="cancel">取消</el-button>-->
                <!--</div>-->
              <!--</div>-->
            <!--</el-dialog>-->
          <!--</div>-->
          <!--<div style="margin-top: 10px">-->
            <!--<el-button @click="encryption" class="payBtn" type="warning" :disabled="disabled">去支付</el-button>-->
          <!--</div>-->
        <!--</div>-->
        <div style="width: 100%;margin: 0 auto;text-align: center;padding-top: 50px;">
          <img :src="url4" style="margin: 0 auto;width:50%;">
        </div>
        <div style="padding-top: 50px;text-align: center;">支付中，请稍后。。。</div>
      </el-main>
      <div id="submitFormDiv"></div>
    </el-container>
  </div>
</template>

<script>
  import AEC from "@/api/aes"
  import {alipay, getTicketDetail, getOrder, getVerifyPay} from "../../api/pay/alipay";
  import urlencode from 'urlencode'
  const ZT = require('../../page/img/logo01.png')
  const pay = require('../../page/img/alipay.png')
  const wechat = require('../../page/img/wechat.png')
  const loading = require('../../page/img/loading.gif')
  export default {
    data () {
      return {
        labelPosition: 'left',
        dialogVisible: false,
        dialogVisible2: false,
        isAliPay:0,
        orderNo:'',
        imgSrc:'',
        winUrl:'',
        code:'',
        //  数据源
        // 密钥key,使用后端传过来的密钥
        key: '',
        appId: '',
        ts: '', // 时间戳
        nonce: '',
        url1: ZT,
        url2: pay,
        url3:wechat,
        url4:loading,
        d: {
          // 支付金额
          money: '',
          // 项目名称
          itemName: '',
          // 收款方
          payeeName: '',
          payer: '',// 付款人姓名
          phone: '', // 手机号
          remark: '',// 备注
          // 支付方式
          payMethod: 'AliPay',
          // 收款部门名称
          departName: '',
          // 订单号
          orderNo: '',
          itemId: '',
          money2: '', // 页面输入框内金额
          ticketId:'',
          status:'',
          payType:'',//支付方式
          isRedirect:true, //是否为app支付
        },
        id: '',
        mobileflag:this.isMobile(),
        disabled: false
      }
    },
    methods: {
      // 获取支付订单信息
      getPayDetail () {
        // 二维码上携带的参数id
        //this.id = this.$route.query.id // 订单编号
        this.d.ticketId = this.$route.query.ticketId
        //this.d.orderNo = this.$route.query.id
        // console.log('id:' + this.id)
        // var orN = sessionStorage.getItem('orderNo')
        // //(orN)
        // if(orN == this.itemId){
        //   this.d = JSON.parse(sessionStorage.getItem('data'))
        //   this.dialogVisible = true
        // }
        // 从URL中获取itemId信息
        // console.log(this.$route.query.id)
        // 再根据itemId查询订单信息，并渲染页面
        getTicketDetail(this.d.ticketId).then((r) => {
          // console.log(r.data.data.status)
          if (r.data.code === 200) {
            this.d.status = r.data.data.status
            // 判断订单状态 0已支付, 1 ,未支付, 2 已作废，3 支付失败
            if (r.data.data.status === 2) {
              console.log(r.data.data.status)
              this.disabled = true
              return this.$message.error('订单已作废，请勿支付！')
            } else {
              this.d.itemName = r.data.data.itemName
              console.log(r.data.data.payeeName)
              this.d.payeeName = r.data.data.payeeName
              // 判断金额是否为固定金额，paytype=1 固定金额，直接显示
              if(r.data.data.money != 0){
                this.d.money2 = parseFloat(Number(r.data.data.money) / 100).toFixed(2)
                this.d.money = r.data.data.money
              }
              //  paytype=2 非固定金额，用户手输
              if(r.data.data.money === 0 || r.data.data.money === null){
                this.d.money = this.d.money2
              }
              this.d.departName = r.data.data.departName
              this.key = r.data.data.secretkey
              this.d.payer = r.data.data.userName
              this.d.phone = r.data.data.userMobile
              this.d.payeeId = r.data.data.payeeId
              this.d.departId = r.data.data.departId
              this.d.itemId = r.data.data.itemId
              this.appId = r.data.data.appId
              this.orderNo = r.data.data.id
              this.d.orderNo = r.data.data.id
              this.d.remark = r.data.data.remark
              this.d.payType = r.data.data.payType
              this.d.payMethod = r.data.data.payCategory
              this.encryption()
            }
          } else {
            return this.$message.error('订单信息加载失败，请重试！')
          }
        })
      },
      // 获取支付订单号——orderNo
      getOrderNo () {
        if(this.d.orderNo != ''){
          return
        }
        getOrder().then((r) => {
          console.log(r)
          if (r.data.code === 200) {
            this.d.orderNo = r.data.data
            console.log(this.d.orderNo)
          } else {
            return this.$message.error('订单号获取失败！')
          }
        })
      },
      // 加密传输——支付信息 支付点击事件
      encryption () {
        this.ts = new Date().getTime()
        this.d.ts = this.ts
        if(this.d.payMethod == 'AliPay'){
          this.d.money = this.d.money2
        } else {
          this.d.money = this.d.money2*100
        }
        // 判断是否获取到ticketId，未获取弹框提示退出页面
        if (this.d.ticketId === '' || this.d.ticketId === null) {
          return this.$message.error('订单信息获取失败，请重新支付！')
        }
        this.d.key = this.key
        //this.d.itemId = this.itemId
        this.nonce = this.createCode(6)
        this.d.nonce = this.nonce
        console.log(this.d)
        const a = AEC.encrypt(JSON.stringify(this.d), this.key)
        // 验证信息
        if(this.d.money2 === '' || this.d.money2 === 0){
          return this.$message.error('请输入支付金额！')
        }
        // 发起支付请求
        alipay(this.orderNo,this.appId,this.nonce,this.ts,this.code,a).then((r) => {
          sessionStorage.setItem('orderNo',this.itemId)
          sessionStorage.setItem('data',JSON.stringify(this.d))
          console.log(r)
          if (r.status === 200) {
            if(this.d.payMethod == 'AliPay'){
              // 打开弹框

              // 跳转支付宝支付页面
              // const divForm = document.getElementsByTagName('divform')
              // if (divForm.length) {
              //   document.body.removeChild(divForm[0])
              // }
              // const div = document.createElement('divform')
              // div.innerHTML = r.data
              // document.body.appendChild(div)
              // document.forms[0].setAttribute('target', '_blank')
              // document.forms[0].submit()
              // window.location.href = r.data
              document.querySelector('#submitFormDiv').innerHTML = r.data;
              this.executeScript(r.data);
              this.dialogVisible = true
            } else if ( this.d.payMethod == 'MicroMessenger'){//微信支付
              if(this.mobileflag && this.isAliPay != 2){//true 手机端网页支付H5
                // 打开弹框
                let url = 'https://pay.hmifo.com/#/successpay?orderNo=' +this.orderNo
                window.location.href = r.data.h5_url + '&redirect_url='+urlencode.encode(url)
                this.dialogVisible = true
              }else if (this.isAliPay == 2){//手机端公众号支付&网页端微信打开
                //alert(r)
                //alert(this.d.orderNo)
                this.dialogVisible = true
                window.WeixinJSBridge.invoke(
                  'getBrandWCPayRequest', {
                    'appId': r.data.appId, // 公众号名称，由商户传入
                    'timeStamp': r.data.timeStamp, // 时间戳，自1970年以来的秒数
                    'nonceStr': r.data.nonceStr, // 随机串
                    'package': r.data.package,
                    'signType': r.data.signType, // 微信签名方式：
                    'paySign': r.data.paySign // 微信签名
                  },
                  function (res) {
                    //alert(JSON.stringify(res))
                    if (res.err_msg === 'get_brand_wcpay_request:ok') {
                      // 使用以上方式判断前端返回,微信团队郑重提示：
                      // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    }
                  }
                )
              }else if (!this.mobileflag && this.isAliPay != 2){//扫码支付
                this.dialogVisible2 = true
                console.log(r.data)
                this.imgSrc = r.data
              }
            }
          } else {
            this.$alert('错误：' + r.data, '提示', {
              confirmButtonText: '确定'
            })
          }
        })
      },
      //支付宝执行
      executeScript(html){
        let reg = /<script[^>]*>([^\x00]+)$/i;
        let htmlBlock = html.split("<\/script>");
        for (let i in htmlBlock){
          let blocks;
          if (blocks = htmlBlock[i].match(reg)){
            let code = blocks[1].replace(/<!--/, '');
            try{
              eval(code) //执行脚本
            }
            catch (e){
            }
          }
        }
      },
      //继续支付
      PayAgain(){
        if(this.d.status !== 1){
          getOrder().then((r) => {
            console.log(r)
            if (r.data.code === 200) {
              this.d.orderNo = r.data.data
              //console.log(this.d.orderNo)
              sessionStorage.clear()
            } else {
              return this.$message.error('订单号获取失败！')
            }
          })
        }
        this.dialogVisible = false
      },
      createCode (length) {
        let code = ''
        const codeLength = length
        const random = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
        // const random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9)
        for (let i = 0; i < codeLength; i++) {
          const index = Math.floor(Math.random() * 9)
          code += random[index]
        }
        return code
      },
      // 弹框按钮——“已完成支付”，验证支付状态
      verifyPay () {
        getVerifyPay(this.d.orderNo,this.d.payMethod).then((r) => {
          if (r.data.code === 200) {
            this.$router.push({ path: '/SuccessPay?flag=0&orderNo='+ this.d.orderNo })
          }
          if (r.data.code == null || r.data.code == undefined) {
            this.$router.push({ path: '/SuccessPay?flag=1&orderNo='+ this.d.orderNo })
          }
        },(error) =>{
          console.log(error)
          this.$router.push({ path: '/SuccessPay?flag=1&orderNo='+ this.d.orderNo })
        })
      },
      //判断是什么
      isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
      },
      //取消支付
      cancel(){
        this.dialogVisible2 = false
      },
      //获取code
      getCode(){
        var code = this.getUrlParam().code
        var local = 'https://pay.hmifo.com/#/GoToPay_D?ticketId=' + this.d.ticketId
        //var local = 'http://192.168.124.39:28081/#/GoToPay2?id=' + this.$route.query.id + '&ticketId=' + this.d.ticketId
        if(code == null || code == ''){
          window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx43dfa54492c30238&redirect_uri='+ urlencode.encode(local) + '&response_type=code&scope=snsapi_base&state='+this.d.ticketId+'#wechat_redirect'
        }else{
          this.code = code
          console.log(code)
        }
        // console.log('----',code)
      },
      getUrlParam(){//获取url返回参数
        var url = location.search
        this.winUrl = url
        //alert('url:' + this.winUrl)
        var theRequest = new Object()
        if (url.indexOf("?") != -1) {
          var str = url.substr(1)
          var strs = str.split("&")
          for(var i = 0; i < strs.length; i ++) {
            theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
          }
        }
        return theRequest
      },
      //判断当前浏览器
      IsWeixinOrAlipay(){
        this.d.ticketId = this.$route.query.ticketId
        // console.log('ticket1'+this.d.ticketId)
        // if(this.d.ticketId === '' || this.d.ticketId === null || this.d.ticketId === undefined){
        //   sessionStorage.setItem('ticketId',this.$route.query.ticketId)
        //   this.d.ticketId = this.$route.query.ticketId
        //   console.log('ticket2'+this.d.ticketId)
        //   this.d.ticketId = this.getUrlParam().state
        // }
        var ua = window.navigator.userAgent.toLowerCase();
        //判断是不是微信
        if ( ua.match(/MicroMessenger/i) == 'micromessenger' ) {

          this.isAliPay = 2
          this.d.payMethod = 'MicroMessenger'
          this.getCode()
          return
        } else if (ua.match(/AlipayClient/i) == 'alipayclient') {//判断是不是支付宝
          this.isAliPay = 1
          this.d.payMethod = 'AliPay'
          return
        } else {//哪个都不是
          this.isAliPay = 0
          return
        }

      }
    },
    mounted () {
      // 加载订单信息
      this.getPayDetail()
      // 获取订单号
      //this.getOrderNo()
      //this.IsWeixinOrAlipay()
      console.log(this.isAliPay)
    },
    beforeRouteEnter(to,from,next){
      console.log(to)
      console.log(from)
      // if(to.name == 'GoToPay'){
      //   alert(1)
      // }
      next(vm =>{
        vm.IsWeixinOrAlipay()
      })
    }
  }
</script>

<style scoped>
  .payWarper{
    max-width: 750px;
    margin: 0 auto;
    height: 100%;
  }
  .payMoney{
    font-size: 14px;
    color: #898989;
    margin-top: -5px;
  }
  input{ width: 65%;}
  /deep/ .el-dialog__body{ padding: 0px 20px 25px 20px; line-height: 22px;}
  /deep/ .el-divider--horizontal{
    margin: 12px 0px 12px !important
  }

  @media screen and (max-width: 992px) {
    /deep/ .el-dialog{
      width: 88% !important;
    }
  }
  .dialog-footer{
    position: relative;
    overflow: hidden;
  }
  .dialog-footer > div{
    float: left;
    width: 50%;
    text-align: center;
  }
  .dialog-footer .dialogBtn{
    width: 80%; padding: 10px !important;
  }
  .payMoney span{
    font-size: 18px;
    color: #000000;
  }
  .line{
    border-right: solid 1px #000000;
    margin:0px 10px;
  }
  .headTitle{
    font-size: 14px;
    font-weight: bold;
  }


  .el-container{
    height: 100%;
    width: 100%;
  }
  .el-header{
    background-color: white;
    /* padding: 20px 30px; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding: 0 15px;

  }
  .el-main{
    background-color: #f2f2f2;
    padding: 0;
  }
  .payTitle{
    font-weight: bold;
    font-size: 20px;
  }
  .payee{
    color:#898989;
    margin-top: 10px;
    font-size: 14px;
  }
  .payForm{
    margin-top: 20px;
  }
  .payFormItem{
    background-color: white;
    padding: 13px 10px;
    margin-top: 10px;
    font-size: 14px;
  label{
    font-weight: bold;
  }
  input{
    border: none;
    outline: none;
    font-size: 16px;
  }
  }
  .payWay{
    margin-top: 20px;
    font-size: 14px;
    color: #898989;
    display: flex;
    justify-content: flex-start;
  }
  .payType{
    font-size: 17px;
    color: #000000;
    width: calc(100% - 80px);
    margin-top: -14px;
  }
  .payType img{
    width: 30px; height: 30px; margin-right: 10px;
  }
  .payTypeItem{ display: flex; align-items: center;
    border-bottom: solid 1px #C8C8C8; height: 50px;}
  .payTypeItem:last-child{ border: none;}
  /deep/.el-radio__label{ display: none;}
  /deep/ .el-radio{ margin-right: 15px;}
  .payBtn{
    width: 80%;
    display: block;
    margin: 0 auto;
    color: black;
    /* max-width: 350px; */
  }
  .Dialog{
  /deep/.el-dialog{
    max-width: 400px;
  }
  /deep/.el-dialog__header{
    padding: 20px 15px 0px 15px;
  }
  /deep/.el-dialog__title{
    line-height: 0px;
    font-size: 14px;
  }
  /deep/.el-dialog__body{
    padding: 0px 20px 30px 20px;
  }
  }
  .dialogBtn{
    width: 60%;
  }
  .moneyInput{
    display: inline;
    border: none;
    background-color: #f2f2f2;
    font-size: 20px;
    font-weight: bold;
    width: 150px;
    outline: none;
  }
  .el-button{
    padding: 12px !important;
  }
</style>
